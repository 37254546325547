<template>
  <b-container fluid class="custom-mode">
    <b-row>
      <b-col md="4">
        <div class="custom-f-lable">
          <label>{{ $t("configurator.Data_Collection") }}</label>
        </div>
        <b-input-group>
          <b-form-input
            v-model="customModeConfig.dataCollectionDuration"
            type="number"
            @input="validateDuration('dataCollectionDuration')"
          />

          <b-input-group-append>
            <b-dropdown
              variant="outline-primary"
              :text="getUnitText(customModeConfig.dataCollectionUnit)"
            >
              <b-dropdown-item @click="setUnit('dataCollectionUnit', 0)"
                >Min</b-dropdown-item
              >
              <b-dropdown-item @click="setUnit('dataCollectionUnit', 1)"
                >Sec</b-dropdown-item
              >
            </b-dropdown>
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col md="4">
        <div class="custom-f-lable">
          <label>{{ $t("configurator.Data_Sending") }}</label>
        </div>
        <b-input-group>
          <b-form-input
            v-model="customModeConfig.dataSendingDuration"
            type="number"
            min="0"
            max="59"
            @input="validateDuration('dataSendingDuration')"
          />
          <b-input-group-append>
            <b-dropdown
              variant="outline-primary"
              :text="getUnitText(customModeConfig.dataSendingUnit)"
            >
              <b-dropdown-item @click="setUnit('dataSendingUnit', 0)"
                >Min</b-dropdown-item
              >
              <b-dropdown-item @click="setUnit('dataSendingUnit', 1)"
                >Sec</b-dropdown-item
              >
            </b-dropdown>
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col md="4">
        <div class="switch-outer">
          <label>{{ $t("configurator.Heart_Beat") }}</label>
          <b-form-checkbox
            switch
            v-model="customModeConfig.isHearbeatEnable"
          ></b-form-checkbox>
        </div>
        <b-input-group>
          <b-form-input
            v-model="customModeConfig.heartBeatDuration"
            type="number"
            min="0"
            max="59"
            @input="validateDuration('heartBeatDuration')"
            :disabled="!customModeConfig.isHearbeatEnable"
          />
          <b-input-group-append>
            <b-dropdown
              variant="outline-primary"
              :text="getUnitText(customModeConfig.heartBeatUnit)"
            >
              <b-dropdown-item @click="setUnit('heartBeatUnit', 0)"
                >Min</b-dropdown-item
              >
              <b-dropdown-item @click="setUnit('heartBeatUnit', 1)"
                >Sec</b-dropdown-item
              >
            </b-dropdown>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div class="desc-1-conf">
          {{ $t("configurator.desc_1_config") }}
        </div>
        <div class="title-1-conf">{{ $t("configurator.Filtration_1") }}</div>
        <div class="desc-2-conf">{{ $t("configurator.desc_2_config") }}</div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="4">
        <div class="switch-outer">
          <label>{{ $t("configurator.Filter_Time") }}</label>
          <b-form-checkbox
            switch
            v-model="customModeConfig.isFilterTimeEnable"
          ></b-form-checkbox>
        </div>
        <b-input-group>
          <b-form-input
            v-model="customModeConfig.filterTime"
            type="number"
            min="0"
            max="59"
            @input="validateDuration('filterTime')"
            :disabled="!customModeConfig.isFilterTimeEnable"
          />
          <b-input-group-append>
            <b-dropdown
              variant="outline-primary"
              :text="getUnitText(customModeConfig.filterTimeUnit)"
            >
              <b-dropdown-item @click="setUnit('filterTimeUnit', 0)"
                >Min</b-dropdown-item
              >
              <b-dropdown-item @click="setUnit('filterTimeUnit', 1)"
                >Sec</b-dropdown-item
              >
            </b-dropdown>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col md="4">
        <div class="switch-outer">
          <label>{{ $t("configurator.Filter_Distance") }}</label>
          <b-form-checkbox
            switch
            v-model="customModeConfig.isFilterDistanceEnable"
          ></b-form-checkbox>
        </div>
        <b-form-input
          v-if="customModeConfig.isFilterDistanceEnable"
          v-model="customModeConfig.filterDistance"
          type="number"
          @input="minZero('filterDistance')"
          min="0"
        />
      </b-col>
      <b-col md="4">
        <div class="switch-outer">
          <label>{{ $t("configurator.Filter_Maximum_Inaccuracy") }}</label>
          <b-form-checkbox
            switch
            v-model="customModeConfig.isFilterMaximumInaccuracyEnable"
          ></b-form-checkbox>
        </div>
        <b-form-input
          v-model="customModeConfig.filterMaximumInaccuracy"
          type="number"
          min="0"
          @input="minZero('filterMaximumInaccuracy')"
          :disabled="!customModeConfig.isFilterMaximumInaccuracyEnable"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="4">
        <div class="switch-outer">
          <label>{{ $t("configurator.Filter_Change_in_Speed") }}</label>
          <b-form-checkbox
            switch
            v-model="customModeConfig.isFilterSpeedEnable"
          ></b-form-checkbox>
        </div>
        <b-form-input
          v-if="customModeConfig.isFilterSpeedEnable"
          v-model="customModeConfig.filterChangeInSpeed"
          type="number"
          @input="minZero('filterChangeInSpeed')"
          min="0"
        />
      </b-col>

      <b-col md="4">
        <div class="switch-outer">
          <label>{{ $t("configurator.Filter_Change_in_Course") }}</label>
          <b-form-checkbox
            switch
            v-model="customModeConfig.isFilterChangeInCourseEnable"
          ></b-form-checkbox>
        </div>
        <b-form-input
          v-if="customModeConfig.isFilterChangeInCourseEnable"
          v-model="customModeConfig.filterChangeInCourse"
          @input="minZero('filterChangeInCourse')"
          type="number"
          min="0"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox
} from "bootstrap-vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox
  },
  data() {
    return {
      customModeConfig: {
        dataCollectionDuration: 5,
        dataCollectionUnit: 0,
        dataSendingDuration: 1,
        dataSendingUnit: 0,
        isHearbeatEnable: true,
        heartBeatDuration: 6,
        heartBeatUnit: 1,
        isFilterMaximumInaccuracyEnable: true,
        filterMaximumInaccuracy: 30,
        isFilterTimeEnable: true,
        filterTime: 10,
        filterTimeUnit: 1,
        isFilterDistanceEnable: true,
        filterDistance: 100,
        isFilterSpeedEnable: true,
        filterChangeInSpeed: 10,
        isFilterChangeInCourseEnable: true,
        filterChangeInCourse: 5
      }
    };
  },
  methods: {
    validateDuration(key) {
      const max = 59;
      setTimeout(() => {
        if (Number(this.customModeConfig[key]) > max) {
          this.customModeConfig[key] = 59;
        } else if (Number(this.customModeConfig[key]) < 0) {
          this.customModeConfig[key] = 0;
        } else if (!Number(this.customModeConfig[key])) {
          this.customModeConfig[key] = 0;
        }
      }, 100);
    },

    minZero(key) {
      setTimeout(() => {
        if (!Number(this.customModeConfig[key])) {
          this.customModeConfig[key] = 0;
        }
      }, 100);
    },
    getUnitText(unit) {
      return unit === 0 ? "Min" : "Sec";
    },
    setUnit(field, value) {
      this.$set(this.customModeConfig, field, value);
    }
  }
};
</script>

<style scoped lang="scss">
label {
  font-weight: bold;
}
.switch-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.custom-f-lable {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  min-height: 24px;
}
.desc-1-conf {
  margin-top: 10px;
}
.desc-2-conf {
  margin-bottom: 10px;
}
.title-1-conf {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}
.custom-mode .btn-outline-primary.dropdown-toggle::after {
  display: none;
}
@media only screen and (max-width: 991px) {
  .switch-outer {
    margin-top: 15px;
  }
  .custom-mode {
    .col-md-4,
    .col-md-12 {
      padding-left: 0px;
      padding-right: 0px;
    }
    .row.mt-3 {
      margin-top: 0px !important;
      margin-bottom: 10px !important;
    }
  }
}
</style>
