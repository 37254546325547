<template>
  <b-card class="position-relative main-card-padding auto-height-card">
    <div class="adduser-btn-row">
      <h4 class="card-title">
        {{ $t("configurator.status_settings") }}
      </h4>
    </div>
    <div>{{ $t("configurator.status_description") }}</div>
    <hr />
    <div>
      <b-form-group>
        <div class="d-flex justify-content-between mb-1">
          <b-form-checkbox
            v-model="statusSetting.EnableStatus"
            class="form-control-merge cursor-pointer"
          >
            {{ $t("configurator.enable_status") }}
          </b-form-checkbox>
        </div>
      </b-form-group>
      <b-form-tags
        v-model="statusSetting.tags"
        input-id="tags-pills"
        tag-variant="primary"
        tag-pills
        separator=" "
        :placeholder="$t('configurator.enter_tags_placeholder')"
        class="no-add-btn text-area-tag"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard, BFormCheckbox, BFormTags, BFormGroup } from "bootstrap-vue";

export default {
  components: { BCard, BFormCheckbox, BFormTags, BFormGroup },
  data() {
    return {
      statusSetting: {
        EnableStatus: false,
        tags: []
      }
    };
  }
};
</script>

<style scoped lang="scss">
.auto-height-card .card-body {
  min-height: auto !important;
}
.no-add-btn >>> .b-form-tags-button {
  display: none !important;
}
.text-area-tag {
  min-height: 60px;
}
@media only screen and (max-width: 991px) {
  .auto-height-card{
    .adduser-btn-row{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
</style>
