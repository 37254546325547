<template>
  <div class="config-comp" :class="{ 'is-config-filter-open': isOpen }">
    <b-card
      class="position-relative main-card-padding auto-height-card card-top"
    >
      <div class="adduser-btn-row mb-0">
        <h4 class="card-title">{{ $t("configurator.Templates") }}</h4>
        <div class="conf-temp-ame">
          <b-form-input
            v-if="$route.query.create || $route.query.id"
            v-model="templateName"
            class="template-input mr-1 mbt-1"
            type="text"
            :placeholder="$t('configurator.EnterTemplateName')"
          />
          <b-button
            v-if="
              !$route.query.id &&
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ADD,
                subject: constants.PERMISSIONS_MODEL.TEMPLATES
              })
            "
            v-b-tooltip.hover.v-primary
            :title="$t('configurator.Add_Templates')"
            variant="primary"
            @click="goToCreateTemplates"
            class="btn-icon mr-1 mbt-1"
          >
            <feather-icon icon="PlusIcon" size="18" class="mr-0-5" />
            <span class="mobile-hidden">{{
              $t("configurator.Add_Templates")
            }}</span>
          </b-button>
          <span
            variant="secondary"
            class="mt-0 mr-1 mbt-1 or-4"
            v-if="
              selectedTemplate &&
              checkAbility({
                action: constants.PERMISSIONS_ACTION.DELETE,
                subject: constants.PERMISSIONS_MODEL.TEMPLATES
              })
            "
            @click="deleteSelectedTemplate(selectedTemplate)"
          >
            <feather-icon
              icon="Trash2Icon"
              class="text-danger action-icon ml-0"
              size="18"
              style="cursor: pointer"
            />
          </span>

          <b-button
            variant="primary"
            :disabled="
              ($refs.WidgetSettings && $refs.WidgetSettings.numberError) ||
              Object.keys($refs.WorkSchedule.workScheduleErrors).length
                ? true
                : false
            "
            class="mt-0 mr-1 mbt-1 or-4"
            @click="sendConfiguration(true)"
            v-if="
              selectedTemplate &&
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ADD,
                subject: constants.PERMISSIONS_MODEL.TEMPLATES
              })
            "
          >
            <feather-icon icon="CopyIcon" size="18" class="mr-0-5" />
            <span class="mobile-hidden">
              {{ $t("configurator.Save_as_configuration") }}
            </span>
          </b-button>
          <b-button
            v-if="
              (selectedTemplate &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.TEMPLATES
                })) ||
              ($route.query.create &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.TEMPLATES
                }))
            "
            variant="primary"
            class="mt-0 mr-1 mbt-1 or-4"
            :disabled="
              (!templateName && $route.query.create) ||
              $refs.WidgetSettings.numberError ||
              Object.keys($refs.WorkSchedule.workScheduleErrors).length
                ? true
                : false
            "
            @click="sendConfiguration(false)"
          >
            <feather-icon icon="SaveIcon" size="18" class="mr-0-5" />
            <span class="mobile-hidden">
              {{ $t("configurator.Send_configuration") }}
            </span>
          </b-button>
          <div class="template-drop mbt-1" v-if="!$route.query.create">
            <v-select
              id="templateList"
              clearable
              v-model="selectedTemplate"
              @input="changeTemplate"
              label="label"
              :placeholder="$t('configurator.SelectTemplate')"
              :reduce="(option) => (option.value ? option.value : option)"
              :options="templateOption"
            />
          </div>

          <b-button
            class="mt-0 mr-1 mobile-hidden ml-1 mbt-1"
            v-b-tooltip.hover.v-primary
            :title="$t('Back')"
            @click="back()"
            variant="outline-primary"
          >
            <span class="mobile-hidden">{{ $t("Back") }}</span>
          </b-button>
        </div>
      </div>
    </b-card>
    <div class="outer-card-top">
      <OperationModes ref="OperationMode" />
      <StatusSettings ref="StatusSettings" />
      <WorkSchedule ref="WorkSchedule" />
      <WidgetSettings
        ref="WidgetSettings"
        :widgetSettings="widgetSettings"
        @updateWidgetSettings="updateWidgetSettings"
      />
    </div>
    <AreYouSureModal
      :data="deleteData"
      :onClose="onClose"
      :removedUser="deleteTemplate"
      :customData="customData"
    />
    <Loader :show="show" />
  </div>
</template>

<script>
import { BButton, BCard, BFormInput, VBTooltip } from "bootstrap-vue";
import vSelect from "vue-select";
import EventBus from "@/EventBus";
import OperationModes from "@/layouts/components/Config/OperationModes.vue";
import StatusSettings from "@/layouts/components/Config/StatusSettings.vue";
import WidgetSettings from "@/layouts/components/Config/WidgetSettings.vue";
import WorkSchedule from "@/layouts/components/Config/WorkSchedule.vue";
import UtilityService from "@/libs/api/utility-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import Loader from "@/layouts/components/Loader.vue";
export default {
  components: {
    OperationModes,
    StatusSettings,
    WidgetSettings,
    WorkSchedule,
    BButton,
    vSelect,
    BCard,
    BFormInput,
    VBTooltip,
    AreYouSureModal,
    Loader
  },
  data() {
    return {
      selectedTemplate: "",
      templateOption: [],
      isOpen: false,
      templateData: {},
      templateName: "",
      widgetSettings: {},
      deviceIds: [],
      deleteData: null,
      customData: {},
      show: false,
      defaultData: {}
    };
  },
  computed: {
    isCreatingTemplate() {
      return this.$route.name === "create-templates" || this.$route.query.id;
    }
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  watch: {},
  created() {
    EventBus.$on("is-customizer-open", this.updateCustomClass);
    EventBus.$on("get-all-device", this.getDevices);
    EventBus.$on("set-device-config", this.getConfig);
  },
  beforeDestroy() {
    EventBus.$off("is-customizer-open");
    EventBus.$off("get-all-device");
    EventBus.$off("set-device-config");
  },
  async mounted() {
    await this.getAllTemplates();
    this.setDefaultData();
    if (this.$route.query.id) {
      await this.getTemplate(this.$route.query.id);
    }
  },
  methods: {
    setDefaultData() {
      this.defaultData = {
        ...this.$refs.OperationMode.config,
        statusSetting: { ...this.$refs.StatusSettings.statusSetting },
        widgetSettings: { ...this.$refs.WidgetSettings.widgetSettings },
        workSchedule: { ...this.$refs.WorkSchedule.workSchedule }
      };
      if (this.defaultData.selectedOperationMode === 2) {
        this.defaultData.customModeConfig = {
          ...this.$refs.OperationMode.$refs.CustomMode.customModeConfig
        };
      }
    },
    async changeTemplate(val) {
      if (!val) {
        this.$router.replace({ query: {} });
        this.selectedTemplate = "";
        this.templateName = "";
        this.setDefaultDataToComponent();
      } else {
        const query = this.$route.query;
        this.$router.replace({ query: { ...query, id: val } });
        await this.getTemplate(val);
      }
    },
    getDevices(e) {
      this.deviceIds = e;
      this.templateData = {
        ...this.$refs.OperationMode.config,
        statusSetting: { ...this.$refs.StatusSettings.statusSetting },
        widgetSettings: { ...this.$refs.WidgetSettings.widgetSettings },
        workSchedule: { ...this.$refs.WorkSchedule.workSchedule }
      };
      if (this.templateData.selectedOperationMode === 2) {
        this.templateData.customModeConfig = {
          ...this.$refs.OperationMode.$refs.CustomMode.customModeConfig
        };
      }
      this.sendConfigurationData(this.templateData);
    },

    updateWidgetSettings(newSettings) {
      console.log("Updating widget settings:", newSettings);
      this.widgetSettings = newSettings;
    },
    updateCustomClass(open) {
      this.isOpen = open;
    },

    async sendConfiguration(save) {
      this.templateData = {
        ...this.$refs.OperationMode.config,
        statusSetting: { ...this.$refs.StatusSettings.statusSetting },
        widgetSettings: { ...this.$refs.WidgetSettings.widgetSettings },
        workSchedule: { ...this.$refs.WorkSchedule.workSchedule }
      };
      if (this.templateData.selectedOperationMode === 2) {
        this.templateData.customModeConfig = {
          ...this.$refs.OperationMode.$refs.CustomMode.customModeConfig
        };
      }
      if (save) {
        this.templateName = this.templateName + " copy";
      }
      this.createTemplate(this.templateData, save);
    },
    goToCreateTemplates() {
      this.$router.replace({ query: { create: true } });
    },
    goToCreateTemplatesClone(templateId) {
      this.$router.push({
        name: "create-templates",
        query: { id: templateId }
      });
    },
    async sendConfigurationData(data) {
      try {
        this.show = true;
        let response;
        if (!Object.keys(data)) {
        }
        //  await this.validationForm();

        let message = this.$t("configurator.CreatedSuccessfully");

        response = await new UtilityService().sendConfiguration({
          data,
          deviceIds:
            this.deviceIds && this.deviceIds.length
              ? [...new Set(this.deviceIds)]
              : this.deviceIds
        });

        this.show = false;
        if (response && response.data) {
          message = this.$t("configurator.ConfigurationSentSuccessfully");
          this.$toast({
            component: ToastificationContent,
            props: {
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async createTemplate(data, isSaveAs) {
      try {
        this.show = true;
        let response;

        //  await this.validationForm();

        let message = this.$t("configurator.CreatedSuccessfully");

        if (this.$route.query.id && !this.$route.query.create && !isSaveAs) {
          response = await new UtilityService().updateTemplate({
            data,
            name: this.templateName,
            id: this.$route.query.id
          });
          message = this.$t("configurator.UpdatedSuccessfully");
        } else {
          response = await new UtilityService().createTemplate({
            data,
            name: this.templateName
          });
        }

        this.show = false;
        if (response && response.data) {
          if (!this.$route.query.id || isSaveAs) {
            this.$router.replace({ query: { id: response.data.id } });
            this.selectedTemplate = response.data.id;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.getAllTemplates();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getConfig(data) {
      try {
        this.templateData = data;

        this.$refs.OperationMode.config = {
          selectedOperationMode: this.templateData.selectedOperationMode,
          isAllowOperationModes: this.templateData.isAllowOperationModes,
          isAllowTurnOffTracking: this.templateData.isAllowTurnOffTracking
        };
        this.$refs.StatusSettings.statusSetting = {
          ...this.templateData.statusSetting
        };
        this.$refs.WidgetSettings.localWidgetSettings = {
          ...this.templateData.widgetSettings
        };
        this.$refs.WorkSchedule.workSchedule = {
          ...this.templateData.workSchedule
        };
        if (this.templateData.selectedOperationMode === 2) {
          const op = this.$refs.OperationMode;
          setTimeout(() => {
            this.$refs.OperationMode.$refs.CustomMode.customModeConfig = {
              ...this.templateData.customModeConfig
            };
          }, 1000);
        }
      } catch (err) {
        this.showErrorToast(err.message || "Error fetching templates");
      }
    },
    async getTemplate(id) {
      try {
        this.show = true;
        const response = await new UtilityService().getTemplate({
          id: id || this.$route.query.id
        });
        if (response?.data) {
          this.selectedTemplate = response.data.id;
          this.templateData = response.data.data;
          this.templateName = response.data.name;

          const tempData = this.templateData;
          this.$refs.OperationMode.config = {
            selectedOperationMode: this.templateData.selectedOperationMode,
            isAllowOperationModes: this.templateData.isAllowOperationModes,
            isAllowTurnOffTracking: this.templateData.isAllowTurnOffTracking
          };
          this.$refs.StatusSettings.statusSetting = {
            ...this.templateData.statusSetting
          };
          this.$refs.WidgetSettings.localWidgetSettings = {
            ...this.templateData.widgetSettings
          };
          this.$refs.WorkSchedule.workSchedule = {
            ...this.templateData.workSchedule
          };
          if (this.templateData.selectedOperationMode === 2) {
            const op = this.$refs.OperationMode;
            setTimeout(() => {
              this.$refs.OperationMode.$refs.CustomMode.customModeConfig = {
                ...this.templateData.customModeConfig
              };
            }, 1000);
          }
        }
        this.show = false;
      } catch (err) {
        this.show = false;
        this.showErrorToast(err.message || "Error fetching templates");
      }
    },
    async getAllTemplates() {
      try {
        this.show = true;
        const response = await new UtilityService().getAllTemplates({
          skip_data: true
        });
        if (response?.data?.list?.length) {
          this.templateOption = response.data.list.map(({ name, id }) => ({
            label: name,
            value: id
          }));
        }
        this.show = false;
      } catch (err) {
        this.showErrorToast(err.message || "Error fetching templates");
      }
    },
    showErrorToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "InfoIcon",
          variant: "danger"
        }
      });
    },
    onClose(isReload) {
      if (isReload) {
        this.getAllTemplates();
      }
      this.$bvModal.hide("modal-sm-remove");
      this.deleteData = null;
      this.customData = null;
    },
    deleteSelectedTemplate(item) {
      this.$bvModal.show("modal-sm-remove");
      this.deleteData = {
        id: item,
        name: this.templateName
      };
    },
    back() {
      this.$router.replace({ query: {} });
      this.selectedTemplate = "";
    },
    async deleteTemplate(props) {
      try {
        this.show = true;
        let req = {};
        req.template_id = props.id;
        let response = await new UtilityService().deleteTemplate(req);
        this.show = false;
        if (response && response.data) {
          this.onClose(true);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("configurator.DeletedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.selectedTemplate = "";
          this.templateName = "";
          this.setDefaultDataToComponent();
          this.getAllTemplates();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    setDefaultDataToComponent() {
      this.templateData = JSON.parse(JSON.stringify(this.defaultData));
      this.$refs.OperationMode.config = {
        selectedOperationMode: this.templateData.selectedOperationMode,
        isAllowOperationModes: this.templateData.isAllowOperationModes,
        isAllowTurnOffTracking: this.templateData.isAllowTurnOffTracking
      };
      this.$refs.StatusSettings.statusSetting = {
        ...this.templateData.statusSetting
      };
      this.$refs.WidgetSettings.localWidgetSettings = {
        ...this.templateData.widgetSettings
      };
      this.$refs.WorkSchedule.workSchedule = {
        ...this.templateData.workSchedule
      };
      if (this.templateData.selectedOperationMode === 2) {
        const op = this.$refs.OperationMode;
        setTimeout(() => {
          this.$refs.OperationMode.$refs.CustomMode.customModeConfig = {
            ...this.templateData.customModeConfig
          };
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.template-drop .v-select .vs__selected {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  display: block;
}
.is-config-filter-open {
  .template-drop .v-select .vs__selected {
    max-width: 110px;
  }
}
</style>

<style scoped lang="scss">
.is-config-filter-open {
  width: calc(100% - 400px);
}
.template-drop,
.template-input {
  width: 220px;
}

.is-config-filter-open {
  .template-drop,
  .template-input {
    width: 180px;
  }
}
.template-name {
  margin-right: 10px;
  width: 30%;
}
.auto-height-card {
  .card-body {
    min-height: auto !important;
  }
  h4.card-title {
    font-weight: 500;
    font-size: 1.285rem;
  }
}
.auto-height-card.card-top {
}
.outer-card-top {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
.auto-height-card.card-bottom {
  margin-top: -15px;
}
.conf-temp-ame {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.is-config-filter-open {
  .conf-temp-ame {
    flex-wrap: wrap;
  }
}
.mr-0-5 {
  margin-right: 6px;
}
.mbt-1 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

@media only screen and (max-width: 991px) {
  .mbt-1.or-4 {
    order: 4;
  }
  .outer-card-top {
    max-height: none;
  }
  .auto-height-card.card-top {
    .adduser-btn-row {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: self-start;
      padding: 2px;
      .conf-temp-ame {
        width: 100%;
        justify-content: flex-start;
        margin-top: 10px;
        flex-wrap: wrap;
        .btn.btn-icon.mr-1.btn-primary {
          order: 2;
        }
        .template-drop,
        .template-input {
          min-width: 100%;
          margin-right: 0px !important;
          margin-bottom: 10px;
        }
        .template-name {
          min-width: 100%;
        }
      }
    }
    .template-name {
      min-width: 100%;
      width: 100%;
      margin-top: 5px !important;
      margin-bottom: 15px;
    }
  }
  .auto-height-card.card-top {
    margin-bottom: -6px;
  }
  .auto-height-card.card-bottom {
    margin-top: -15px;
  }
}
</style>
