<template>
  <b-card class="position-relative main-card-padding auto-height-card card-top-1">
    <div class="adduser-btn-row">
      <h4 class="card-title">
        {{ $t("configurator.operation_modes") }}
      </h4>
    </div>
    <div>{{ $t("configurator.description") }}</div>
    <hr />
    <div>
      <div class="card-row">
        <div
          v-for="(mode, index) in ['Light', 'Standard', 'Custom']"
          :key="mode"
          :class="[
            'card-bg',
            { 'active-card': config.selectedOperationMode === index }
          ]"
          @click="selectMode(index)"
        >
          <div class="card-title-config">{{ $t(`configurator.${mode}`) }}</div>
          <div class="card-desc">
            <template v-if="mode === 'Light'">
              {{ $t("configurator.light_description") }}
              <hr />
              <div class="check-opction">
                <feather-icon icon="CheckCircleIcon" />
                <span>{{ $t("configurator.Low_battery") }}</span>
              </div>
              <div class="check-opction">
                <feather-icon icon="CheckCircleIcon" />
                <span>{{ $t("configurator.General_idea") }}</span>
              </div>
            </template>
            <template v-else-if="mode === 'Standard'">
              {{ $t("configurator.standard_description") }}
              <hr />
              <div class="check-opction">
                <feather-icon icon="CheckCircleIcon" />
                <span>{{ $t("configurator.Well_balanced") }}</span>
              </div>
              <div class="check-opction">
                <feather-icon icon="CheckCircleIcon" />
                <span>{{ $t("configurator.High_accuracy") }}</span>
              </div>
              <div class="check-opction">
                <feather-icon icon="CheckCircleIcon" />
                <span>{{ $t("configurator.Battery_lasting") }}</span>
              </div>
            </template>
            <template v-else>
              {{ $t("configurator.custom_description") }}
              <hr />
              <div class="check-opction">
                <feather-icon icon="CheckCircleIcon" />
                <span>{{ $t("configurator.Data_collection") }}</span>
              </div>
              <div class="check-opction">
                <feather-icon icon="CheckCircleIcon" />
                <span>{{ $t("configurator.Data_sending") }}</span>
              </div>
              <div class="check-opction">
                <feather-icon icon="CheckCircleIcon" />
                <span>{{ $t("configurator.Filtration") }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <b-form-group>
        <div class="d-flex justify-content-between mb-1">
          <b-form-checkbox
            v-model="config.isAllowOperationModes"
            class="form-control-merge cursor-pointer"
          >
            {{ $t("configurator.allow_mobile_selection") }}
          </b-form-checkbox>
        </div>
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-between mb-1">
          <b-form-checkbox
            v-model="config.isAllowTurnOffTracking"
            class="form-control-merge cursor-pointer"
          >
            {{ $t("configurator.allow_tracking_off") }}
          </b-form-checkbox>
        </div>
      </b-form-group>
    </div>
    <CustomMode v-if="config.selectedOperationMode === 2" ref="CustomMode" />
  </b-card>
</template>

<script>

import { BCard, BFormCheckbox, BFormGroup } from "bootstrap-vue";
import CustomMode from "./CustomMode.vue";
export default {
  components: { BCard, BFormCheckbox, BFormGroup, CustomMode },
  data() {
    return {
      
      config: {
        selectedOperationMode: 1, // 0=Light, 1=Standard, 2=Custom
        isAllowOperationModes: true,
        isAllowTurnOffTracking: true
      },
      
    };
  },
  methods: {
    selectMode(modeIndex) {
      this.config.selectedOperationMode = modeIndex;
    }
  }
};
</script>

<style scoped lang="scss">
.auto-height-card .card-body {
  min-height: auto !important;
}

.card-row {
  display: flex;
  margin-bottom: 30px;
  margin-top: 30px;
  .card-bg {
    background: #f5f5f5;
    border-radius: 8px;
    margin-right: 35px;
    min-height: 200px;
    width: 25%;
    border: 1px solid transparent;
    padding: 15px;
    &:last-child {
      margin-right: 0px;
    }
  }
  .card-bg.active-card {
    border: 1px solid var(--primary);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
    scale: 1.1;
  }
}
.is-config-filter-open {
  .card-bg {
    width: 33.33%;
  }
}

.dark-layout {
  .card-row {
    .card-bg {
      background: var(--unit-bg-dark);
    }
  }
}
.card-title-config {
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  margin-bottom: 10px;
}
.card-desc {
  font-size: 12px;
  text-align: center;
}
.card-bg.active-card {
  .card-title-config,
  .card-desc {
    color: var(--primary);
  }
}
.check-opction {
  text-align: left;
  margin-bottom: 5px;
  span {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .auto-height-card{
    .adduser-btn-row{
      padding-left: 0px;
      padding-right: 0px;
    }
    .card-row{
      flex-wrap: wrap;
      .card-bg{
          width: 100%;
          margin-right:0px;
          margin-bottom: 10px;
          scale: 1;
      }
    }
  }
}
</style>
