<template>
  <b-card class="position-relative main-card-padding auto-height-card">
    <div class="adduser-btn-row">
      <h4 class="card-title">
        {{ $t("configurator.work_on_schedule") }}
      </h4>
    </div>
    <div>{{ $t("configurator.work_schedule_description") }}</div>
    <hr />

    <b-form-group>
      <div class="d-flex justify-content-between mb-1">
        <b-form-checkbox
          v-model="workSchedule.EnableWorkOnSchedule"
          class="form-control-merge cursor-pointer"
        >
          {{ $t("configurator.enable_work_schedule") }}
        </b-form-checkbox>
      </div>
    </b-form-group>

    <div v-for="(dayKey, index) in days" :key="index" class="work-schedule-row">
      <b-form-group>
        <div class="d-flex justify-content-between">
          <b-form-checkbox
            v-model="workSchedule.selectedDays[dayKey]"
            class="form-control-merge cursor-pointer"
          >
            {{ $t(`configurator.days.${dayKey}`) }}
          </b-form-checkbox>
        </div>
      </b-form-group>

      <!-- Work Schedule Rows -->
      <div
        v-for="(timeSlot, slotIndex) in workSchedule.workTimes[dayKey]"
        :key="slotIndex"
        class="d-flex align-items-center repeated-row"
      >
        <div class="blank-check"></div>
        <div class="time-outer">
          <v-select
            v-model="timeSlot.from"
            :options="workOption"
            :clearable="false"
          />
        </div>

        <div class="dash">-</div>

        <div class="time-outer">
          <v-select
            v-model="timeSlot.to"
            :options="workOption"
            :clearable="false"
          />
        </div>

        <feather-icon
          @click="removeWorkSlot(dayKey, slotIndex)"
          icon="Trash2Icon"
          class="text-danger action-icon ml-1 delete-btn"
          size="18"
        />
      </div>

      <!-- Show Error Message -->
      <div v-if="workScheduleErrors[dayKey]" class="text-danger mt-1">
        {{ workScheduleErrors[dayKey] }}
      </div>

      <!-- Add Hours Button (Disabled if there's an error) -->
      <div
        class="add-row-text"
        @click="addWorkSlot(dayKey)"
        :class="{ disabled: disableAddHours[dayKey] }"
        :style="{
          pointerEvents: disableAddHours[dayKey] ? 'none' : 'auto',
          opacity: disableAddHours[dayKey] ? 0.5 : 1
        }"
      >
        + Add hours
      </div>
    </div>

    <b-form-group class="mb-0">
      <div class="d-flex justify-content-between mb-0">
        <b-form-checkbox
          v-model="workSchedule.UserTurnOffWork"
          class="form-control-merge cursor-pointer"
        >
          {{ $t("configurator.allow_user_turn_off") }}
        </b-form-checkbox>
      </div>
    </b-form-group>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import { BCard, BFormCheckbox, BFormGroup, BButton } from "bootstrap-vue";

export default {
  components: { BCard, BFormCheckbox, vSelect, BFormGroup, BButton },
  data() {
    return {
      hoverIndex: null,
      workSchedule: {
        UserTurnOffWork: false,
        EnableWorkOnSchedule: false,
        workTimes: {
          monday: [{ from: null, to: null }],
          tuesday: [{ from: null, to: null }],
          wednesday: [{ from: null, to: null }],
          thursday: [{ from: null, to: null }],
          friday: [{ from: null, to: null }],
          saturday: [{ from: null, to: null }],
          sunday: [{ from: null, to: null }]
        },

        selectedDays: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false
        }
      },
      workScheduleErrors: {}, // Stores errors for each day
      disableAddHours: {}, // Stores the disable flag for each day
      workOption: this.generateTimeSlots(),
      days: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
      ]
    };
  },
  watch: {
    "workSchedule.workTimes": {
      handler: function () {
        this.validateWorkSchedule();
      },
      deep: true
    }
  },
  methods: {
    generateTimeSlots() {
      let timeSlots = [];
      for (let h = 0; h < 24; h++) {
        for (let m = 0; m < 60; m += 15) {
          const hour = String(h).padStart(2, "0");
          const minute = String(m).padStart(2, "0");
          timeSlots.push(`${hour}:${minute}`);
        }
      }
      return timeSlots;
    },
    addWorkSlot(dayKey) {
      this.workSchedule.workTimes[dayKey].push({ from: null, to: null });
    },
    removeWorkSlot(dayKey, slotIndex) {
      this.workSchedule.workTimes[dayKey].splice(slotIndex, 1);
    },
    validateWorkSchedule() {
      let errors = {};
      let disableButtons = {};

      Object.keys(this.workSchedule.workTimes).forEach((day) => {
        const timeSlots = this.workSchedule.workTimes[day]
          .filter((slot) => slot.from && slot.to) // Ignore empty slots
          .map((slot) => ({
            from: this.parseTime(slot.from),
            to: this.parseTime(slot.to),
            rawFrom: slot.from,
            rawTo: slot.to
          }));

        let hasOverlap = false;
        let hasInvalidTime = false;

        // Check if from time is less than to time
        for (let slot of timeSlots) {
          if (slot.from >= slot.to) {
            errors[
              day
            ] = `Invalid time range: '${slot.rawFrom} - ${slot.rawTo}' on ${day}`;
            hasInvalidTime = true;
            break;
          }
        }

        if (!hasInvalidTime) {
          // Sort time slots by 'from' time
          timeSlots.sort((a, b) => a.from - b.from);

          // Check for overlapping time slots
          for (let i = 0; i < timeSlots.length - 1; i++) {
            if (timeSlots[i].to > timeSlots[i + 1].from) {
              errors[day] = `Overlapping time slots found on ${day}`;
              hasOverlap = true;
              break;
            }
          }
        }

        // Disable "Add Hours" button if there's an error
        disableButtons[day] = hasOverlap || hasInvalidTime;
      });

      this.workScheduleErrors = errors; // Update error messages dynamically
      this.disableAddHours = disableButtons;
    },

    parseTime(timeString) {
      const [hours, minutes] = timeString.split(":").map(Number);
      return hours * 60 + minutes; // Convert time to minutes for easy comparison
    }
  }
};
</script>

<style scoped lang="scss">
.repeated-row {
  width: 51%;
  margin-bottom: 10px;
}
.text-danger.mt-1 {
  position: absolute;
  bottom: -17px;
  padding-left: 131px;
}
.add-row-text {
  margin-bottom: 10px;
  cursor: pointer;
}
.repeated-row:first-of-type {
  width: calc(51% - 115px);
  .blank-check {
    min-width: 0;
  }
  .delete-btn {
    display: none;
  }
}
.auto-height-card .card-body {
  min-height: auto !important;
}
.work-schedule-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  position: relative;
  .form-group {
    margin-bottom: 0px;
    min-width: 115px;
  }
  .blank-check {
    min-width: 115px;
  }
}
.time-outer {
  min-width: 110px;
  margin: 0 15px;
}
@media only screen and (max-width: 991px) {
  .auto-height-card {
    .adduser-btn-row {
      padding-left: 0px;
      padding-right: 0px;
    }
    .work-schedule-row {
      flex-wrap: wrap;
      .form-group {
        width: 100%;
      }
      .dash {
        display: none;
      }
      .time-outer {
        width: 100%;
        margin-top: 10px;
      }
      .repeated-row {
        flex-wrap: wrap;
        width: 100%;
      }
      .add-row-text {
        margin-left: 13px;
      }
      .action-icon.ml-1.delete-btn {
        margin-top: 10px;
      }
    }
  }
}
</style>
