<template>
  <b-card class="position-relative main-card-padding auto-height-card">
    <div class="adduser-btn-row">
      <h4 class="card-title">{{ $t("configurator.widget_settings") }}</h4>
    </div>
    <div>{{ $t("configurator.widget_description") }}</div>
    <hr />

    <div>
      <div class="parent-permission">
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-form-checkbox
              v-model="localWidgetSettings.isEnablePhotos"
              class="form-control-merge cursor-pointer"
              @change="emitSettings"
            >
              {{ $t("configurator.send_photos") }}
            </b-form-checkbox>
          </div>
        </b-form-group>
      </div>

      <div class="child-permission">
        <b-form-checkbox
          v-model="localWidgetSettings.isCompressPhotos"
          class="form-control-merge cursor-pointer"
          @change="emitSettings"
        >
          {{ $t("configurator.compress_photos") }}
        </b-form-checkbox>
        <div class="child-permission">
          {{ $t("configurator.compress_photos_description") }}
        </div>
      </div>

      <div class="parent-permission">
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-form-checkbox
              v-model="localWidgetSettings.isEnableQrCode"
              class="form-control-merge cursor-pointer"
              @change="emitSettings"
            >
              {{ $t("configurator.scan_qr_codes") }}
            </b-form-checkbox>
          </div>
        </b-form-group>
      </div>

      <div class="parent-permission">
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-form-checkbox
              v-model="localWidgetSettings.isEnableSOS"
              class="form-control-merge cursor-pointer"
              @change="emitSettings"
            >
              {{ $t("configurator.send_sos") }}
            </b-form-checkbox>
          </div>
        </b-form-group>
      </div>

      <div class="child-permission">
        {{ $t("configurator.sos_description") }}
        <div class="input-outer-config">
          <b-form-input
            v-model="sos"
            type="number"
            :placeholder="$t('configurator.sos_placeholder')"
            @input="validateNumber"
            @change="emitSettings"
          />
        </div>
      </div>
      <span style="color: red">{{ numberError }}</span>
      <div class="parent-permission">
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-form-checkbox
              v-model="localWidgetSettings.isEnableSendLocation"
              class="form-control-merge cursor-pointer"
              @change="emitSettings"
            >
              {{ $t("configurator.send_location") }}
            </b-form-checkbox>
          </div>
        </b-form-group>
      </div>

      <div class="parent-permission">
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-form-checkbox
              v-model="localWidgetSettings.isEnableChat"
              class="form-control-merge cursor-pointer"
              @change="emitSettings"
            >
              {{ $t("configurator.use_chat_function") }}
            </b-form-checkbox>
          </div>
        </b-form-group>
      </div>

      <div class="parent-permission">
        <b-form-group>
          <div class="d-flex justify-content-between">
            <b-form-checkbox
              v-model="localWidgetSettings.isEnableBleBeacon"
              class="form-control-merge cursor-pointer"
              @change="emitSettings"
            >
              {{ $t("configurator.ble_beacon") }}
            </b-form-checkbox>
          </div>
        </b-form-group>
      </div>

      <div class="child-permission">
        {{ $t("configurator.ble_beacon_description") }}
        <div class="input-outer-config">
          <b-form-input
            v-model="Uid"
            :placeholder="$t('configurator.ble_beacon_placeholder')"
            @input="emitSettings"
          />
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BFormCheckbox, BFormInput, BFormGroup } from "bootstrap-vue";

export default {
  components: { BCard, BFormCheckbox, BFormInput, BFormGroup },
  props: {
    widgetSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localWidgetSettings: { ...this.widgetSettings },
      sos: this.widgetSettings.sos || "",
      Uid: this.widgetSettings.Uid || "",
      numberError: ""
    };
  },
  watch: {
    widgetSettings: {
      deep: true,
      handler(newSettings) {
        this.localWidgetSettings = { ...newSettings };
        this.sos = newSettings.sos || "";
        this.Uid = newSettings.Uid || "";
      }
    }
  },
  methods: {
    emitSettings() {
      const updatedSettings = {
        ...this.localWidgetSettings,
        sos: this.sos,
        Uid: this.Uid
      };

      console.log("Final Widget Settings Data:", updatedSettings);
      this.$emit("updateWidgetSettings", updatedSettings);
    },
    validateNumber() {
      const regex = /^[0-9]{6,15}$/; // Regular expression for 6 to 15 digits only
      if (this.sos && !regex.test(this.sos)) {
        this.numberError =
          "Number must be between 6 and 15 digits long and contain only numbers.";
      } else {
        this.numberError = "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.child-permission {
  padding-left: 30px;
  margin-bottom: 10px;
}
.parent-permission {
  margin-bottom: 10px;
}
.child-permission .child-permission {
  margin-top: 10px;
}
.input-outer-config {
  width: 270px;
  margin-top: 10px;
}
.auto-height-card .card-body {
  min-height: auto !important;
}
.parent-permission {
  .form-group {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 991px) {
  .auto-height-card {
    .adduser-btn-row {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
</style>
